var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fixed-center text-center" },
    [
      _c("p"),
      _vm._m(0),
      _c(
        "q-btn",
        {
          staticStyle: { width: "200px" },
          attrs: { color: "secondary" },
          on: {
            click: function ($event) {
              return _vm.$router.push("/")
            },
          },
        },
        [_vm._v("Go back")]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-faded" }, [
      _vm._v("Sorry, nothing here..."),
      _c("strong", [_vm._v("(404)")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }